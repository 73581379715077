<template>
  <v-container>
    <head-title text="Dtes"></head-title>
    <menu-grid :items="items"></menu-grid>
  </v-container>
</template>

<script>

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    MenuGrid:  () => import("@/components/base/MenuGrid"),
  },
  data: () => ({
    permissions: [],
    items: [
      {
        title: "Consulta DTEs",
        ico: "file-invoice",
        to: { name: "ListDtes" },
        permission: "_view_dte_all",
      },
      {
        title: "Anulaciones",
        ico: "ban",
        to: { name: "AnullmentDtes" },
        permission: "_view_dtes_cancel",
      },
    ],
  }),
};
</script>
